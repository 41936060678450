import isBoolean from 'lodash/isBoolean';
import '@zegal/components/src/base/common/vendor/customPolyfill';
import App from '@zegal/components/src/base/core';
import RegisterStore from './entities/registration';

// test environment doesn't have actions object
if (!App.actions) {
	App.actions = {};
}

App.integrationsInit = async () => {
	try {
		const integrations = await import('@zegal/components/src/integrations/app');

		if (!App.stores.general.theme.intercom) {
			if (isBoolean(App.getSetting('integrations').intercom?.enabled)) {
				App.getSetting('integrations').intercom.enabled = false;
			}
		}

		await integrations.start(undefined, App);
		// add in custom Zegal events.
		const integrationsModule = App.module('Integrations');
		const Events = await import('./common/events');
		if (integrationsModule && integrationsModule.MixPanel) {
			integrationsModule.MixPanel.addEvents((logEvent) => Events.default(logEvent));
		}

		if (integrationsModule && integrationsModule.GA) {
			integrationsModule.GA.addEvents((logEvent) => Events.default(logEvent));
		}

		if (integrationsModule && integrationsModule.Intercom) {
			integrationsModule.Intercom.addEvents((logEvent) => Events.default(logEvent));
		}
	} catch (e) {
		console.error(e);
		App.log('app_error', {message: e.message, type: 'integration-loading-error'});
		App.actions.error(e);
	}
};

App.actions.setupStores = async () => {
	App.stores.register = RegisterStore.create({}, {app: App});
	await App.integrationsInit();

	return Promise.resolve();
};

export default App;
