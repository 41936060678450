import {get} from 'lodash';
import App from '@zegal/reg/src/app';

export default class ZegalError extends Error {
	constructor(type = 'common-error', ...params) {
		// Pass remaining arguments (including vendor specific ones) to parent constructor
		super(...params);

		// Maintains proper stack trace for where our error was thrown (only available on V8)
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, Error);
		}

		// Custom debugging information
		this.type = type;
		this.date = new Date();
		// log error
		// App.radio.trigger('app:error', get(params, '[0]'))

		App.log('app_error', {message: get(params, '[0]')}, App.getConfig('domain'));
	}
}
