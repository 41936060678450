import {fade} from '@material-ui/core/styles/colorManipulator';
import {giveGradient} from '../../global.style';

export default (theme) => ({
	backDrop: {
		backgroundColor: fade(theme.palette.background.default, 0.6)
	},
	customMediumWidth: {
		maxWidth: theme.typography.pxToRem(780)
	},

	dialog: {
		width: '100%',
		maxHeight: '100%',
		margin: 0,

		[theme.breakpoints.up('md')]: {
			margin: theme.typography.pxToRem(48),
			maxHeight: `calc(100% - 96px)`
		}
	},

	staticHeight: {
		[theme.breakpoints.up('md')]: {
			minHeight: '60%'
		}
	},

	fullScreen: {
		borderRadius: 0
	},

	stickOnBottomXs: {
		[theme.breakpoints.down('xs')]: {
			position: 'fixed',
			bottom: 0,
			minHeight: '30vh',
			height: 'auto !important'
		}
	},

	dialogPaper: {
		borderRadius: 0,
		boxShadow: `0 ${theme.typography.pxToRem(1)} ${theme.typography.pxToRem(3)} rgba(0, 0, 0, .12), 0 ${theme.typography.pxToRem(1)} ${theme.typography.pxToRem(2)} rgba(0, 0, 0, .20)`,

		[theme.breakpoints.up('md')]: {
			margin: theme.typography.pxToRem(12),
			height: 'auto',
			maxHeight: 'calc(100% - 12px)'
		},

		[theme.breakpoints.down('xs')]: {
			height: '100%'
		},

		'&$fullScreen': {
			maxWidth: '100%',
			width: '100%',
			height: '100%',
			maxHeight: '100%',
			margin: 0
		}
	},

	showTopBorder: {
		'&::before': {
			content: '""',
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			height: theme.typography.pxToRem(4),
			background: giveGradient(theme)
		},
	},

	paperMd: {
		maxWidth: '100%',

		[theme.breakpoints.up('md')]: {
			maxWidth: theme.typography.pxToRem(900),

			// custom cubic scrollbar
			'&::-webkit-scrollbar': {
				width: '.4rem',
				height: '.4rem'
			},

			'&::-webkit-scrollbar-thumb': {
				backgroundColor: '#cccccc',

				'&:hover': {
					backgroundColor: '#cccccc',
				}
			}
		}
	},

	// color

	secondary: {
		backgroundColor: theme.palette.background.default
	},

	white: {
		backgroundColor: theme.palette.background.paper
	},

	dialogTitle: {
		fontSize: '1rem',
		fontWeight: 700,
		padding: `${theme.typography.pxToRem(28)} ${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(12)}`
	},

	dialogContent: {
		overflow: 'unset',
		padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(24)}`,

		'&.collapsed': {
			padding: 0
		},

		'&::-webkit-scrollbar': {
			width: 0
		},

		'&::-webkit-scrollbar-thumb': {
			backgroundColor: 'transparent'
		}
	},

	dialogCloseButton: {
		position: 'absolute',
		right: theme.typography.pxToRem(5),
		top: theme.typography.pxToRem(5),
		zIndex: theme.zIndex.appBar
	}
});
