import axios from 'axios';
import {each} from 'lodash';

export const setUpCustomHeaders = (App, customHeaders) => {
	each(customHeaders, (header, key) => {
		App.setHeader(key, header);
	});

	return setAxiosDefaultHeaders(App);
};

export const setAxiosDefaultHeaders = (App) => {
	const customHeaders = App.getCustomHeaders(true);
	if (customHeaders) {
		each(customHeaders, (header) => {
			// send header only if exist
			if (header.value && header.value !== '0') {
				axios.defaults.headers.common[header.key] = header.value;
			}
		});
	}

	return App.getHeaders();
};
