import React from 'react';

import {MuiThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

export const WrapThemeProvider = (theme) => ({children}) => {
	return (
		<React.Fragment>
			<MuiThemeProvider theme={theme}>
				<CssBaseline />

				{children}
			</MuiThemeProvider>
		</React.Fragment>
	);
};

export default WrapThemeProvider;
