import isBoolean from 'lodash/isBoolean';

export const setZapierConfig = ({app}) => {
	const isZapierEnabledInConfig = app.getConfig('integrations')?.zapier?.enabled;
	const isZapierEnabledInWL = app.stores.general?.theme?.zapier?.enable;

	if (isBoolean(isZapierEnabledInConfig)) {
		app.getConfig('integrations').zapier.enabled = !!isZapierEnabledInWL;
	}
	return app.getConfig('integrations')?.zapier?.enabled;
};
