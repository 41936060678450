export default (theme) => {
	const warning = theme.color ? theme.color.default.warning : '#e68945';
	const success = theme.color ? theme.color.default.success : '#4cbac0';
	const danger = theme.color ? theme.color.default.error : '#de5b68';
	const primary = theme.color ? theme.palette.primary.main : '#4cbac0';
	const secondary = theme.color ? theme.palette.secondary.main : '#cccccc';
	const textPrimary = theme.color ? theme.palette.text.primary : '#414751';
	const primaryLight = theme.palette.primary.light;

	return {
		icon: {
			color: theme.palette.text.secondary,
			fontSize: theme.typography.pxToRem(22),
			margin: `0 ${theme.typography.pxToRem(6)}`,

			'& svg': {
				width: theme.typography.pxToRem(19),
				height: theme.typography.pxToRem(19)
			}
		},

		isHover: {
			cursor: 'pointer',

			'&:focus, &:hover, &:visited': {
				opacity: 0.8
			}
		},

		isDraggable: {
			cursor: 'grab',

			'&:focus, &:hover, &:visited': {
				opacity: 0.8
			}
		},

		iconColor: {
			'&inherit': {
				'&, &:focus, &:hover, &:visited': {
					color: 'inherit'
				}
			},

			'&textPrimary': {
				'&, &:focus, &:hover, &:visited': {
					color: textPrimary
				}
			},

			'&primary': {
				'&, &:focus, &:hover, &:visited': {
					color: primary
				}
			},

			'&primaryLight': {
				'&, &:focus, &:hover, &:visited': {
					color: primaryLight
				}
			},

			'&secondary': {
				'&, &:focus, &:hover, &:visited': {
					color: secondary
				}
			},

			// default
			'&white': {
				'&, &:focus, &:hover, &:visited': {
					color: '#fff'
				}
			},

			// default
			'&black': {
				'&, &:focus, &:hover, &:visited': {
					color: theme.palette.text.primary
				}
			},

			'&success': {
				'&, &:focus, &:hover, &:visited': {
					color: success
				}
			},

			'&danger': {
				'&, &:focus, &:hover, &:visited': {
					color: danger
				}
			},

			'&warning': {
				'&, &:focus, &:hover, &:visited': {
					color: warning
				}
			}
		},

		iconSize: {
			'&xs': {
				fontSize: theme.typography.pxToRem(18),

				'& svg': {
					width: theme.typography.pxToRem(20),
					height: theme.typography.pxToRem(20)
				}
			},

			'&sm': {
				fontSize: theme.typography.pxToRem(20),

				'& svg': {
					width: theme.typography.pxToRem(22),
					height: theme.typography.pxToRem(22)
				}
			},

			'&md': {
				fontSize: theme.typography.pxToRem(40),

				'& svg': {
					width: theme.typography.pxToRem(40),
					height: theme.typography.pxToRem(40)
				},

				'& .material-icons': {
					fontSize: theme.typography.pxToRem(40)
				}
			},

			'&lg': {
				fontSize: theme.typography.pxToRem(52),

				'& svg': {
					width: theme.typography.pxToRem(54),
					height: theme.typography.pxToRem(54)
				}
			},

			'&xl': {
				fontSize: theme.typography.pxToRem(66),

				'& svg': {
					width: theme.typography.pxToRem(68),
					height: theme.typography.pxToRem(68)
				}
			},

			'&xxl': {
				// it is only for svg
				width: '100%',

				'& svg': {
					width: '80%',
					height: theme.typography.pxToRem(120),

					[theme.breakpoints.up('lg')]: {
						width: '40%'
					}
				}
			},

			'&hero': {
				// it is only for svg
				width: '100%',

				'& svg': {
					width: '80%',
					height: theme.typography.pxToRem(200),
					margin: `${theme.typography.pxToRem(10)} auto`,

					[theme.breakpoints.up('lg')]: {
						width: '40%',
						height: theme.typography.pxToRem(300),
						margin: `${theme.typography.pxToRem(20)} auto`
					}
				}
			},

			'&auto': {
				'&, & svg': {
					width: 'auto',
					height: 'auto'
				}
			}
		},

		marginRatio: {
			'&sm': {
				margin: `0 ${theme.typography.pxToRem(2)}`
			},

			'&lg': {
				margin: `0 ${theme.typography.pxToRem(10)}`
			},

			'&xl': {
				margin: `0 ${theme.typography.pxToRem(12)}`
			},

			'&only-right': {
				margin: `0 ${theme.typography.pxToRem(8)} 0 0`
			},

			'&only-left': {
				margin: `0 0 0 ${theme.typography.pxToRem(8)}`
			},

			'&none': {
				margin: 0
			}
		}
	};
};
