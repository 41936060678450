import '@zegal/components/src/base/common/vendor/customPolyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import has from 'lodash/has';
import map from 'lodash/map';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import {Provider} from 'mobx-react';
import {BrowserRouter as Router} from 'react-router-dom';

import App from '@zegal/reg/src/app';
import info from './info.json';
import config from './common/config/app';

// style stuff
import {WrapThemeProvider} from '@zegal/reg/src/common/components/wrapThemeProvider';
import {setZapierConfig} from '@zegal/reg/src/common/setZapierConfig';

import './styles/spinner.scss';

// import registerServiceWorker from './registerServiceWorker';
// import '@zegal/components/src/base/auth/app'
// import '@zegal/components/src/base/common/utils'
// console.log('Starting app:', info.version, App);

App.fetchI18N = (domain) => {
	if (domain) {
		return import('./common/scribe.domain.json');
	}

	return import('./common/scribe.json');
};

App.actions.redirectUrl = (location) => {
	const subDomain = location.split('register');
	if (subDomain[1]) {
		App.settings.AppRoot = `${App.getConfig('AppRoot').split('app')[0]}app${subDomain[1]}`;
	}
};

// when org is switched, or logout

App.defaults = {
	version: info.version,
	homepath: '/',
	platform: 'reg'
};

App.UnAuthRoutes = [];

App.userModules = [import('./modules/home/app.js')];

App.adminModules = [];

App.processes.afterSetup = () => {
	App.cache.xhrCalls = [];
	// console.log('-----------SETUP COMPLETE');
	let title = App.getConfig('customTitle');
	if (title) {
		App.actions.changeTitle(title, {manual: true});
	}

	// set axios common header equal to api version for app
	// axios.defaults.headers.common['X-Dragon-Law-API-Version'] = '0.10.0'

	App.settings.APIDTEPrefix = '/reg/';
	App.settings.homepath = '/';

	if (App.getConfig('debug')) {
		window.app = App;
	}
};
export class AppWrapper extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			authRoutes: false
		};
	}

	componentDidMount() {
		App.processes.afterStart = () => {
			// App.log('setup:admin:modules:loaded', 'Zegal', 3);
			App.actions.setupStores().then(() => {
				App.stores.markets.fetch();
				let routes = [];
				// routes = App.Auth.routes // disable auth routes

				routes.push({
					path: '/',
					options: {exact: true},
					component: App.loadFile({loader: () => import('./modules/home/index')}),
					public: true
				});
				routes.push({
					path: '/new-flow',
					options: {exact: true},
					component: App.loadFile({loader: () => import('./modules/register/register')}),
					public: true
				});

				routes.push({
					path: '/steps',
					options: {exact: true},
					component: App.loadFile({loader: () => import('./modules/stepsLayout/index')}),
					public: true
				});

				routes.push({
					path: '/404',
					options: {exact: true},
					component: App.loadFile({
						loader: () => import('@zegal/components/src/components/404')
					}),
					public: true
				});

				routes.push({
					path: '/goToApp',
					options: {exact: true},
					component: App.loadFile({
						loader: () => import('./modules/register/components/goToApp')
					}),
					public: true
				});

				App.actions.redirectUrl(window.location.origin);
				const directReg = App.parseFromURL('submit');
				this.setState(
					{
						authRoutes: routes,
						loading: false
					},
					() => {
						App.stores.register.trackViewRegistration(directReg);
						setZapierConfig({app: App});
					}
				);
			});
		};
	}

	render() {
		const defaultTheme = get(App, 'stores.general.theme.themeDefault');
		if (this.state.loading || !defaultTheme) {
			return (
				<div className='bubblingGWrapper'>
					{' '}
					<div className='bubblingG'>
						{' '}
						<span id='bubblingG_1' /> <span id='bubblingG_2' /> <span id='bubblingG_3' />{' '}
					</div>{' '}
				</div>
			);
		}
		const ThemeProvider = WrapThemeProvider(defaultTheme);

		App.actions.setupModals(ThemeProvider);
		return (
			<Provider {...App.stores}>
				<Router {...App.stores}>
					<div id='app'>
						<ThemeProvider>
							{/* WHEN we ARE LOGGED IN */}
							{this.state.authRoutes &&
								this.state.authRoutes.map((route, i) => (
									<App.RouteBuilder key={'auth-route-' + i} {...route} />
								))}

							{/* always load all routes, */}
							{/* if unauthed, it will redirect to login */}
							{/* then back to correct page after login  */}
							{map(App.modules, (module) => {
								if (isObject(module) && has(module, 'getRoutes')) {
									return map(module.getRoutes, (route, i) => {
										return <App.RouteBuilder key={module.channelName + 'route-' + i} {...route} />;
									});
								}

								if (isObject(module) && has(module, 'routeList') && module.routeList) {
									return map(module.routeList, (route, i) => {
										return <App.RouteBuilder key={module.channelName + 'route-' + i} {...route} />;
									});
								}
							})}
						</ThemeProvider>
					</div>
				</Router>
			</Provider>
		);
	}
}

function renderApp() {
	ReactDOM.render(React.createElement(AppWrapper, {stores: App.Stores}), document.getElementById('glb-content'));
}

const setupStores = () => {
	return Promise.all([import('./common/stores/general')]).then((results) => {
		const [General] = results;
		App.stores.general = General.default.create();
	});
};

App.start({config}, {renderApp}, setupStores);

export default App;

// if (hot) {
// 	hot(module)(renderApp)
// }
