export default {
	/**
	 *
	 * STAGING CONFIG
	 *
	 */

	APIRoot: 'https://api.staging.drabayashi.net', // Staging
	ZAPIRoot: 'https://zapi.staging.drabayashi.net', // Staging
	APIPort: '443',
	SocketPort: '443',
	registrationRoot: 'https://register.staging.drabayashi.net',
	AppRoot: 'https://dte-app.staging.drabayashi.net',
	zegalRoot: 'https://dte-app.staging.drabayashi.net/',

	// 0 - Prod (Only log errors)
	// 1 - Log to Kafka
	// 2 - Log in console
	// 3 - Just plain crazy
	logDisplayLevel: 1,
	logSaveLevel: 1,
	logLimit: 1,
	logAppErrors: true,
	maxAjaxRetries: 1,

	namespace: 'register.staging.drabayashi.net',
	domain: 'reg',
	customTitle: '-',
	prefillWhitelist: {
		firstName: '#firstname-input',
		lastName: '#lastname-input',
		company: '#company-input',
		companySize: '#companysize-input',
		industry: '#industry-input',
		market: '#market-input',
		phone: '#phone-input',
		email: '#email-input',
		promo: '#promo-input',
		redirect: false
	},

	Stripe: 'https://api.stripe.com/v1/tokens',
	StripeKey: 'pk_test_SIFUJZEk4803VP6DbuhoWc3Q',
	StripeUserAgent: 'stripe.js%2F15cb6da',

	features: {
		auth: {
			disableStatusCheck: true,
			disableAPIKey: true
		},

		useV2Data: false,
		registration: false,
		sockets: false,
		emailConfirm: true,
		allowPlusInQueryString: true,

		cookies: {
			enabled: true,
			register: 'DragonRegister',
			secure: true,
			domain: 'drabayashi.net',
			geoDomainkey: 'DragonRegisterGeo'
		},

		integrations: {
			mixPanel: {
				enabled: true,
				passive: true,
				key: '13ed044f41a947f47908b2b309b42d2b'
			},
			intercom: {
				enabled: false,
				default: 'qftv3qdb', // for not logged in intercom
				passive: true // <- an integration token is not required to load this
			},
			zapier: {
				enabled: true,
				urls: {
					emailVerified: 'https://hooks.zapier.com/hooks/catch/819070/5lni00/',
					accountCreated: 'https://hooks.zapier.com/hooks/catch/819070/5lnipe/'
				}
			},
			gtm: {
				enabled: true,
				key: 'GTM-N6T5LR'
			},
			ga: {
				enabled: true,
				key: 'UA-77470195-3'
			}
		},
		ignoreConfirmEmail: true,
		ajaxMode: 'Axios',
		setViewTemplateUrlByJURI: false
	},

	debug: false, // add app to window, console logs etc

	throwErrors: true,
	whiteLabel: false,

	payments: {
		stripe: {
			HKG: 'pk_test_myGkHKfJQD1Q9WLnJROGrqVI',
			DEFAULT: 'pk_test_myGkHKfJQD1Q9WLnJROGrqVI' // <- required!!
		}
	},
	passwordValidation: 'required|min:14|max:100',
	freePlanName: 'NO-PAID-PRIMARY-PLAN'
};
