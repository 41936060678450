const fontSize = 14;
const htmlFontSize = 16;
const coef = fontSize / 14;

const pxToRem = (size) => {
	return `${(size / htmlFontSize) * coef}rem`;
};

const focusShadow = `0 ${pxToRem(3)} ${pxToRem(10)} ${pxToRem(-1)} rgba(0, 0, 0, .15)`;
const focusOneShadow = `0 ${pxToRem(3)} ${pxToRem(5)} 0 rgba(0, 0, 0, .15)`;
const inActiveOpacity = 1;

// backgrounds
const primaryBackground = ({palette}) => ({
	'&, &:focus, &:hover, &:visited': {
		backgroundColor: palette.primary.main,
		color: palette.primary.contrastText
	}
});

const secondaryBackground = ({palette}) => ({
	'&, &:focus, &:hover, &:visited': {
		backgroundColor: palette.secondary.main,
		color: palette.secondary.contrastText
	}
});

const dangerBackground = ({palette}) => ({
	'&, &:focus, &:hover, &:visited': {
		backgroundColor: palette.error.main,
		color: palette.error.contrastText
	}
});

const warningBackground = ({palette, color}) => ({
	'&, &:focus, &:hover, &:visited': {
		backgroundColor: color.default.warning,
		color: palette.text.primary
	}
});

const disabledBackground = ({color, palette}) => ({
	'&, &:focus, &:hover, &:visited': {
		backgroundColor: color.default.disabled,
		color: palette.text.primary,
		cursor: 'default'
	}
});

const getImportant = (value) => {
	return `${value} !important`;
};

// Margin ratio
const marginRatio = ({ratio, breakpoints}) => {
	const topBottom = ratio ? ratio.space.topBottom : '1.3rem';

	return {
		'&xs': {
			marginBottom: pxToRem(8)
		},

		'&sm': {
			marginBottom: 'calc(' + topBottom + ' / 2)',

			[breakpoints.up('md')]: {
				marginBottom: topBottom
			}
		},

		'&md': {
			marginBottom: pxToRem(16),

			[breakpoints.up('md')]: {
				marginBottom: pxToRem(24)
			}
		},

		'&lg': {
			marginBottom: topBottom,

			[breakpoints.up('md')]: {
				marginBottom: 'calc(' + topBottom + ' * 2)'
			}
		},

		'&xl': {
			marginBottom: 'calc(' + topBottom + ' * 11)'
		},

		'&none': {
			margin: 0
		},

		'&top-auto': {
			marginTop: 'auto'
		}
	};
};

// Padding ratio
const paddingRatio = ({ratio, breakpoints}) => {
	const topBottom = ratio ? ratio.space.topBottom : '1.3rem';
	const rightLeft = ratio ? ratio.space.rightLeft : '.875rem';

	return {
		'&xs': {
			padding: pxToRem(8)
		},

		'&sm': {
			padding: pxToRem(12)
		},

		'&md': {
			padding: `${topBottom} ${rightLeft}`
		},

		'&lg': {
			paddingTop: 'calc(' + topBottom + ' * 1.4)',
			paddingRight: 'calc(' + rightLeft + ' * 1.4)',
			paddingBottom: 'calc(' + topBottom + ' * 1.4)',
			paddingLeft: 'calc(' + rightLeft + ' * 1.4)',

			[breakpoints.up('md')]: {
				paddingTop: 'calc(' + topBottom + ' * 2.6)',
				paddingRight: 'calc(' + rightLeft + ' * 2.6)',
				paddingBottom: 'calc(' + topBottom + ' * 2.6)',
				paddingLeft: 'calc(' + rightLeft + ' * 2.6)'
			}
		},

		'&xl': {
			paddingTop: 'calc(' + topBottom + ' * 1.4)',
			paddingRight: 'calc(' + rightLeft + ' * 1.4)',
			paddingBottom: 'calc(' + topBottom + ' * 1.4)',
			paddingLeft: 'calc(' + rightLeft + ' * 1.4)',

			[breakpoints.up('md')]: {
				paddingTop: 'calc(' + topBottom + ' * 3.2)',
				paddingRight: 'calc(' + rightLeft + ' * 3.6)',
				paddingBottom: 'calc(' + topBottom + ' * 3.2)',
				paddingLeft: 'calc(' + rightLeft + ' * 3.6)'
			}
		},

		'&modal-adjustment': {
			paddingTop: 'calc(' + topBottom + ' * 2.2)',
			paddingRight: 'calc(' + rightLeft + ' * 1)',
			paddingBottom: 'calc(' + topBottom + ' * 2.2)',
			paddingLeft: 'calc(' + rightLeft + ' * 1)',

			[breakpoints.up('sm')]: {
				paddingTop: 'calc(' + topBottom + ' * 2.2)',
				paddingRight: 'calc(' + rightLeft + ' * 1.6)',
				paddingBottom: 'calc(' + topBottom + ' * 2.2)',
				paddingLeft: 'calc(' + rightLeft + ' * 1.6)'
			}
		},

		'&none': {
			padding: `0 !important`
		}
	};
};

function giveGradient({color}) {
	const gradient = {
		one: color ? color.default.main_gradient_left : '#4CBAC0',
		two: color ? color.default.main_gradient_right : '#265395'
	};

	return `linear-gradient(to right, ${gradient.one}, ${gradient.two})`;
}

const activeItem = ({color}) => ({
	backgroundColor: color.default.active,
	fontWeight: 700
});

const headerBoxShadow = () => ({
	boxShadow: `${pxToRem(4)} 0 ${pxToRem(5)} rgba(0, 0, 0, .08)`
});

const buttonBoxShadow = `0 ${pxToRem(1)} ${pxToRem(3)} 0 rgb(0, 0, 0, .16)`;

const ratio = {
	// layout volume
	size: {
		// Header
		header: pxToRem(56),
		headerLargeScreen: pxToRem(64),

		// Sidebar
		sidebar: pxToRem(260),
		sidebarLargeScreen: pxToRem(310)
	},

	// margin and padding in 'rem'
	space: {
		topBottom: '.9rem',
		rightLeft: '.9rem'
	}
};

const previewPaperWidth = '210mm';
const previewPaperWidthInPx = 210 * 3.779527559; // MilliMeter Pixel 1 mm = 3.779527559 px
const signaturePadHeight = 250; // in px
const signaturePadWidth = 542;

const dragAndDrop = (isDragging) => ({
	overflowX: isDragging ? 'unset' : 'auto',
	padding: isDragging && '1.30rem 1rem',
	border: isDragging && `${pxToRem(1)} solid #f2f2f0`,
	backgroundColor: 'inherit',
	boxShadow: isDragging && '.3819820591rem .3819820591rem 1rem rgba(71, 71, 62, .1)'
});

export {
	focusShadow,
	focusOneShadow,
	inActiveOpacity,
	primaryBackground,
	secondaryBackground,
	dangerBackground,
	warningBackground,
	disabledBackground,
	getImportant,
	marginRatio,
	paddingRatio,
	activeItem,
	headerBoxShadow,
	buttonBoxShadow,
	dragAndDrop,
	ratio,
	previewPaperWidth,
	previewPaperWidthInPx,
	signaturePadHeight,
	signaturePadWidth,
	giveGradient,
	pxToRem
};
