import {emailRegEx} from '@zegal/components/src/base/common/utils/regEx';

export const EMAIL_NOT_AVAILABLE = 'Email entered is already registered';
export const errorCheckAvailableNotFound = 'Entities: checkAvailable is not defined for the provided store.';

export const NO_SPACE_MSG = 'Spaces are not allowed.';

export const emailValidationWithRegEx = (email = '') => {
	return emailRegEx.test(String(email).toLowerCase());
};

export const non_ascii_apostrophe_dash_space = {
	message: 'The :attribute may only contain letters, dashes, apostrophes, spaces.',
	rule: (val, params, validator) => {
		return validator.helpers.testRegex(val, /([\p{L}-]+)/gu);
	}
};

export const good_email = {
	message: 'The :attribute must be a valid email address.',
	rule: (val, params, validator) => {
		return validator.helpers.testRegex(val.replace(/ /g, ''), emailRegEx);
	}
};

export const alpha_num_apostrophe_dash_space = {
	message: 'The :attribute may only contain letters, numbers, dashes, apostrophes, dots and spaces.',
	rule: (val, params, validator) => {
		return validator.helpers.testRegex(val, /^[A-Z0-9_-\s'.]*$/i);
	}
};

export const no_spaces = {
	message: NO_SPACE_MSG,
	rule: (currentValue, params, validator) => {
		return !validator.helpers.testRegex(currentValue, ' ');
	}
};

export const zegal_validation_company_name = {
	message:
		'The :attribute may only contain letters, numbers, dashes, !, (, ), #, &, "," apostrophes, dots, @ and spaces.',
	rule: (val, params, validator) => {
		return validator.helpers.testRegex(val, /^[A-Z0-9_-\s'.!#&(),@]*$/i);
	}
};

// TODO: refactor, return only boolen in promise
export const uniqueEmail = (App, store, opt = {}) => {
	return {
		message: EMAIL_NOT_AVAILABLE,
		rule: async (val) => {
			try {
				if (!store.checkAvailable) {
					// console.warn(`Simple validation custom rule 'uniqueEmail' has been updated`)
					// throw new Error(errorCheckAvailableNotFound)
					return Promise.reject({errorMessage: errorCheckAvailableNotFound});
				}

				// console.log('val && App.cache.goodEmail !== val', val, App.cache.goodEmail, App.cache.goodEmail !== val, serverCall());
				if (val && App.cache.goodEmail !== val && emailValidationWithRegEx(val)) {
					// alert('checking')
					App.cache.goodEmail = val;
					// const emailCheck = await serverCall()(`${checkAvailableEndPoint(val)}`, {}, 'GET');
					const emailCheck = await store.checkAvailable(val);
					if (emailCheck) {
						opt.success && opt.success();
						return Promise.resolve({emailCheck});
					}
					opt.error && opt.error();
					return Promise.reject(false);
				}
			} catch (error) {
				App && App.actions.error(error);
				console.error(error.message);
			}
		}
	};
};
