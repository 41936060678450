import {extend} from 'lodash';

import App from '@zegal/components/src/base/base';
import Modal from '@zegal/components/src/components/modals/modal';

/**
 * When triggered displays an overview modal - ReactJS Components only
 *
 * @param {Function} component - React Component to display
 * @param {Object} props - Options
 * @param {Function} callback - Callback to init when modal is closed
 */
export const modalReact = (component, props, callback) => {
	return App.actions.loadComponent(
		Modal,
		extend(
			{
				component,
				callback
			},
			{
				childProps: props,
				provide: ['general']
			}
		)
	);
};
