import {generateId} from '@zegal/components/src/base/common/utils/misc';

/**
 * Enhance an object with ajax functions
 *
 * @param {Object} App - Object to add functions too
 */
export default (App) => {
	App.activeAjax = {};

	App.activeAjaxAdd = (xhr) => {
		const id = generateId();
		xhr._id = id;
		App.activeAjax[id] = xhr;
	};

	App.activeAjaxRemove = (id) => {
		delete App.activeAjax[id];
	};

	App.setCurrentCall = function (xhr) {
		var id = generateId();
		// App.log('Setting curent call to:', id, 'App', 1);

		App.currentCall = id;
		App.currentXhr = xhr;

		return App.currentCall;
	};

	App.getCurrentCall = function () {
		return App.currentCall;
	};

	App.cancelCurrentCall = function (xhr) {
		if (xhr) {
			if (xhr.abort) {
				xhr.abort();
			}
		} else {
			if (App.currentXhr && App.currentXhr.abort) {
				App.currentXhr.abort();
			}
		}
	};

	return App;
};
