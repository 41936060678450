import {flow, getEnv} from 'mobx-state-tree';
import {each, includes, get} from 'lodash';

import junkEmailDomains from '@zegal/reg/src/common/config/junkEmailDomains';
import {setUpCustomHeaders} from '@zegal/components/src/base/common/setupAxios';
import AppError from '@zegal/reg/src/common/errors/generalError';
import {cookieAxe, cookieSet, cookieGet} from '@zegal/components/src/base/common/utils/cookie';

import {lsRemove, lsGet} from '@zegal/components/src/base/common/utils/localstorage';

import App from '@zegal/reg/src/app';

export const ORG_ID_NOT_FOUND = 'Org ID required to add Payment method.';
export const JURI_REQUIRED = 'Jurisdiction required.';
export const PLAN_CODE_REQUIRED = 'Plan code required.';
export const REFERRAL_CODE_LABEL = '_fprom_track';

export default (self) => ({
	checkForJunkEmailAdress() {
		let junkFound = false;
		each(junkEmailDomains, (domain) => {
			if (includes(self.email, domain)) {
				junkFound = true;
				// self.removeJunkDomain()
				// self.setKey('email', '')
				return false;
			}
		});
		return junkFound;
	},
	// removes domain from email
	removeJunkDomain(email = self.email) {
		email = email.split('@');
		self.setKey('email', email[0]);
		return email;
	},

	checkEmailIfAvailable: flow(function* (email) {
		if (self.isValidEmail(email)) {
			const response = yield self.serverCall(self.checkAvailableEndPoint(email), null, 'GET');
			// !response.available && self.setKey('email', '')
			return response.available && !response?.user;
		}
		return true;
	}),

	submitFormData: flow(function* () {
		try {
			const {primary_name} = self;
			let data = {
				...self.toJSON(),
				primary_name
			};
			// data.contact_number = `${data.dial_code}${data.contact_number}`;
			data._fprom_track = self.appReferralCode();
			data.namespace = lsGet('namespace')?.title;

			const response = yield self.serverCall(self.registrationEndpoint(), data, 'POST', {
				withResponseHeader: true
			});
			if (response._id) {
				const Token = get(response.headers, App.settings.headers.FireballKey.key.toLowerCase());
				return {
					response,
					Token,
					data
				};
			} else {
				// App.log('app_error', {message})
				return new AppError('Registration failed.');
			}
		} catch (e) {
			App.actions.error(e, true);
			return e;
		}
	}),

	registerWithoutPassword: flow(function* registerWithoutPassword(data = {}) {
		try {
			data._fprom_track = self.appReferralCode();
			data.namespace = lsGet('namespace')?.title;
			let response = yield self.serverCall(self.usersBase, data, 'POST', {withResponseHeader: true});
			const token = get(response.headers, App.settings.headers.FireballKey.key.toLowerCase());
			App.event('registration', 'create_account', {
				label: 'direct',
				...data
			});
			return {
				token,
				email: data.email,
				_id: response._id,
				primary_org_id: response.orgId
			};
		} catch (error) {
			App.actions.error(error);
			throw error;
		}
	}),

	appReferralCode() {
		// get _fprom_track from cookie
		return cookieGet(REFERRAL_CODE_LABEL, null, true);
	},

	updateOrgProfile(_id, data) {
		return self.serverCall(self.profileUpdateEndpoint(_id), data, 'PUT');
	},

	pushToGTM(email) {
		if (App.getConfig('integrations').gtm.enabled) {
			App.Integrations.GTM.sendEvent('new user', {label: 'new user registration', value: email}, 'registration');
		}
	},

	clearOldAuthInfo() {
		const dragonAuthInfo = App.settings.headers.AuthDomain.key;
		const regDomain = App.getConfig('cookies').register;

		cookieAxe(App)(dragonAuthInfo);
		cookieAxe(App)(dragonAuthInfo, {path: '/', domain: App.getConfig('cookies').domain});
		lsRemove(dragonAuthInfo);

		cookieAxe(App)(regDomain);
		cookieAxe(App)(regDomain, {path: '/', domain: App.getConfig('cookies').domain});
		lsRemove(regDomain);

		return {
			ls: lsGet(dragonAuthInfo),
			cookie: cookieGet(dragonAuthInfo)
		};
	},

	saveAuthDataToCookie(Token, response, register_ref, redirect) {
		self.clearOldAuthInfo();
		return cookieSet(App)(App.getConfig('cookies').register, {
			// get our token for auth
			token: Token,
			userid: response._id,
			email: response.email,
			// cookie's redirect needs to take precedence in case this isn't a traditional marketing signup (i.e. invited to sign)
			redirect,
			register_ref: register_ref || 'standard'
		});
	},

	sendConfirmation: flow(function* (data, Token) {
		const {email, _id} = data;
		const App = getEnv(self).app;

		setUpCustomHeaders(App, {
			FireballKey: Token,
			Username: email
		});

		const confirmation = yield self.serverCall(self.sendConfirmationEndpoint(_id), {}, 'POST');
		App.event('registration', 'create_account', {
			label: 'normal',
			...data
		});
		return confirmation;
	}),

	trackableUserData(data = self.toJSON()) {
		let {register_ref} = self.getRegisterCookie() || {register_ref: 'standard'};
		data = {...data, ...{register_ref, password: 'XXXXXX', has_consented: data.consent || false}};
		data.password && delete data.password;
		delete data.consent;
		return data;
	},

	trackVerifiedEmail() {
		let _logData = self.trackableUserData();
		return self.pushToZapier('email-verified', _logData);
	},

	trackAbandonRegistration() {
		window.addEventListener('beforeunload', function () {
			let _logData = self.trackableUserData();
			App.event('register', 'abandon_registration', _logData);
		});
	},

	trackViewRegistration(directReg) {
		App.event('registration', 'view_registration', {
			label: directReg ? 'direct' : 'normal'
		});
	},

	logFinalData: async (data) => {
		App.event('registration', 'submit_sign_up', data);
		const zap = await self.pushToZapier('account-created', data); // log to zapier
		return zap;
	},

	startNewSessionLog(data) {
		App.event('user:register:success', data);
		App.event('session', 'login', {
			label: 'auto'
		});
	},

	/**
	 * Get the 'DragonRegister' cookie
	 * @returns cookie {obj|bool} - cookie data if set, or false if not set
	 */
	getRegisterCookie: (cookie = cookieGet(App.getConfig('cookies').register)) => {
		return cookie || false;
	},

	pushToZapier(stage, data) {
		const _zap = get(App.getConfig('integrations'), 'zapier');
		const zapierEnabled = get(_zap, 'enabled');
		if (zapierEnabled) {
			const url = self.zapierURLs(stage);
			return self.submitToZapier(url, data);
		}
		return Promise.resolve(); // return Promise resolve in case of zapier disabled
	},

	zapierURLs(stage) {
		const urls = {
			'email-verified': App.getConfig('integrations.zapier.urls.emailVerified'),
			'account-created': App.getConfig('integrations.zapier.urls.accountCreated')
		};

		return urls[stage];
	},

	submitToZapier(url, data) {
		try {
			// const {register_ref} = self.getRegisterCookie()
			// const location = App.Common.Utils.cookieGet(App.getConfig('cookies').geoDomainkey, 'country')
			const location = App.stores.user.location.country;
			data = {
				...data,
				...{
					timestamp: `ts${Date.now()}`,
					location
				}
			};
			data.dial_code = App.Integrations.Zapier.sterilize(data.dial_code);
			App.getConfig('debug') && console.warn('--> ', url, data);
			return App.Integrations.Zapier.push(url, data);
		} catch (e) {
			App.actions.error(e);
		}
	},

	configByJURI: flow(function* (juri, namespace) {
		try {
			namespace = namespace === 'zegal' ? '' : namespace;

			return yield self.serverCall(self.getConfigbyJuriURL(juri, namespace), {}, 'GET');
		} catch (e) {
			App.actions.error(e);
		}
	}),

	addCardconfigByJURI: flow(function* (juri) {
		try {
			if (!juri) {
				throw new Error(JURI_REQUIRED);
			}
			return yield self.serverCall(self.getAddCardConfigbyJuriURL(juri), {}, 'GET');
		} catch (e) {
			App.actions.error(e);
			return e.message;
		}
	}),

	addPaymentMethods: flow(function* (token) {
		try {
			const data = self?.userData || {};
			data.token = token;
			if (!data.primary_org_id) {
				throw new Error(ORG_ID_NOT_FOUND);
			}
			return yield self.serverCall(self.addPaymentMethodURI(data.primary_org_id), data, 'POST');
		} catch (e) {
			App.actions.error(e);
			return {
				error: true,
				message: e?.data?.message || e?.message
			};
		}
	}),

	redirectToApp: (redirect = true, history = {}) => {
		window.mixpanel = null;
		let url = App.getConfig('AppRoot');
		if (self.templateName) {
			url = `${App.getConfig('AppRoot')}?template_name=${self.templateName}`;
		}

		if (redirect) {
			setTimeout(() => {
				history.push && history.push('/');

				window.location = url;
			}, 1000);
		} else {
			return url;
		}
	},

	setUserData(data) {
		const {email, primary_org_id} = data;
		self.setKey('userData', {
			email,
			primary_org_id,
			default: true
		});
	},

	processNext(opt, _window = window) {
		// https://developer.mozilla.org/en-US/docs/Web/API/Window/top
		// This is to check if we're inside an iframe or not.
		// window.self === window.top means we're not inside an iframe

		if (_window.self === _window.top) {
			opt.window && opt.window();
		} else {
			// we're inside an iframe, show the `Go to App` button.
			opt.iframe && opt.iframe();
		}
	},

	checkAvailableProxy(email) {
		if (self.cacheEmail !== email) {
			return self.checkAvailable(email);
		}

		return true;
	},

	order: flow(function* (plan_code) {
		try {
			const orgData = self.userData;
			if (!orgData.primary_org_id) {
				throw new Error(ORG_ID_NOT_FOUND);
			}

			if (!plan_code) {
				throw new Error(PLAN_CODE_REQUIRED);
			}

			const data = {
				accepted_tc: true,
				coupon: '',
				items: [
					{
						plan_code,
						quantity: 1,
						type: 'primary'
					}
				]
			};
			return yield self.serverCall(self.getOrderEndpoint(orgData.primary_org_id), data, 'POST');
		} catch (e) {
			App.actions.error(e);
			return e.message;
		}
	}),

	pay: flow(function* () {
		try {
			const orgData = self.userData;
			if (!orgData.primary_org_id) {
				throw new Error(ORG_ID_NOT_FOUND);
			}
			return yield self.serverCall(self.getPayEndpoint(orgData.primary_org_id), {}, 'POST');
		} catch (e) {
			App.actions.error(e);
			return e.message;
		}
	}),

	async submitRegisterForm(someData) {
		const FireballKey = App.getHeader('FireballKey').key;
		const data = {
			email: someData?.email,
			password: someData?.password
		};

		try {
			const result = await self.serverCall(self.registerEndpoint(), data, 'POST', {withResponseHeader: true});
			// App.actions.message({message, good: true});
			if (result?._id) {
				const data = self.trackableUserData();
				const Token = get(result.headers, FireballKey.toLowerCase());
				self.setUserData(result, Token);
				await self.logFinalData(data);

				const {register_ref, redirect} = self.getRegisterCookie();
				self.startNewSessionLog(data);
				await self.saveAuthDataToCookie(Token, result, register_ref, redirect);
				App.stores.user.assignTokenAndEmail(App.stores.user.getAuthInfo());

				return {
					result,
					Token,
					data
				};
			} else {
				return new AppError('Registration failed.');
			}
		} catch (err) {
			console.error(err);
			const errorMessage = err?.data?.err?.message;
			// if (errorMessage.startsWith('UserExistsError: ')) {
			// 	// App.actions.error('User Already Exists', true);
			// 	App.actions.message({message: 'User Already Exists', bad: true});
			// 	return;
			// }
			App.actions.error(errorMessage, true);
		}
	}
});
