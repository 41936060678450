export const splitFile = (filename = '') => filename.split('.');

export const getFilename = (filename) => {
	const pieces = splitFile(filename);

	if (pieces.length === 1) {
		return pieces[0];
	}

	return pieces[pieces.length - 2] || '';
};

export const getExtention = (filename) => {
	const pieces = splitFile(filename);

	// no ext
	if (pieces.length === 1) {
		return '';
	}

	return pieces[pieces.length - 1] || '';
};

export const loadFile = (src, onload) => {
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.onload = onload;
	script.src = src;
	document.head.appendChild(script);
	return script;
};

export const base64ToBrowser = (buffer) => {
	return window.btoa(
		[].slice
			.call(new Uint8Array(buffer))
			.map(function (bin) {
				return String.fromCharCode(bin);
			})
			.join('')
	);
};

export const pdfToBase64 = (urlOrPdf) => {
	return fetch(urlOrPdf)
		.then(function (response) {
			return response.arrayBuffer();
		})
		.then(base64ToBrowser);
};
