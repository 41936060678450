import random from 'lodash/random';

/**
 * Returns true if it is large screen device
 *
 * @returns {boolean}
 */
export const isLargeScreen = (largeScreenWidth: number): boolean => window.innerWidth >= largeScreenWidth;

/**
 * Returns true if screen is larger than 1024
 *
 * @returns {boolean}
 */
export const windowInnerWidthIs1024 = (): boolean => isLargeScreen(1024);

export const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

export const openTab = (url: string) => {
	let newTab = window.open(url, '_blank');
	// @ts-ignore
	newTab.opener = null;
	// If the window couldn't be opened, the returned value is instead null
	// https://developer.mozilla.org/en-US/docs/Web/API/Window/open#Return_value
	newTab && newTab.focus();
};
export const goToURL = (url: string) => {
	const win = window.open(url, '_self');
	// @ts-ignore
	win.opener = null;
};

/**
 * Generate a random number of greater length than 15;
 *
 * @returns {string} random numbers and letters
 */
export const generateId = function () {
	let number;
	let gen = () => Math.random().toString(36).slice(2) + random(999999);

	do {
		number = gen();
	} while (number.length < 15);

	return number;
};

/**
 * Calculate percentage
 *
 * You don't need both remaining and complete, just one
 *
 * @param {object} data - eg: {
 *                            total: 10,
 *                            remaining: 7,
 *                            complate: 3,
 *                        }
 *
 * @returns {number} percentage, eg: 50
 */
export const calculatePercent = function (data) {
	var percent = 100;

	if (data.complate) {
		// not done
	}

	if (data.total !== 0) {
		// get percent remaining
		percent = (data.remaining / data.total) * 100;
		// round to nearest 5
		percent = Math.round(percent / 5) * 5;
		// convert to percent completed
		percent = 100 - percent;
	}

	return percent;
};

export const mapArrayToUpper = (data) => {
	return data.map((format) => format.toUpperCase());
};

export const mapArrayToUpperList = (data) => {
	return mapArrayToUpper(data).join(', ');
};

export async function convertBlobToBase64(blob: Blob): Promise<ProgressEvent> {
	return await new Promise((resolve, reject) => {
		let reader = new FileReader();

		reader.onload = function (event) {
			resolve(event);
		};

		reader.onerror = function (event) {
			reject(event);
		};

		reader.readAsDataURL(blob);
	});
}
