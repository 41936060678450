import {fade} from '@material-ui/core/styles/colorManipulator';
import {primaryBackground, secondaryBackground, dangerBackground, warningBackground} from '@zegal/components/src/components/assets/global.style';

const buttonStyle = (theme) => {
	return {
		button: {
			minHeight: 'auto',
			minWidth: theme.typography.pxToRem(145),
			backgroundColor: theme.color.dl_lightgrey,
			color: theme.color.dl_white,
			boxShadow: `none`,
			border: 'none',
			borderRadius: theme.typography.pxToRem(3),
			position: 'relative',
			padding: `${theme.typography.pxToRem(7.5)}`,
			margin: `${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(1)}`,
			fontSize: theme.typography.body1.fontSize,
			fontWeight: theme.typography.fontWeightRegular,
			textTransform: 'none',
			letterSpacing: 0,
			lineHeight: '1.3',
			textAlign: 'center',
			transition: 'none',

			'&:hover,&:focus': {
				backgroundColor: theme.color.dl_lightgrey,
				color: theme.color.dl_white
			},

			// transitioning label
			'& label, & .button-label': {
				cursor: 'inherit',

				'& > *:last-child:not(strong)': {
					alignSelf: 'baseline'
				}
			},

			// if button has spinner
			'&.has-spinner': {
				// spinner wrapper
				'& *[data-zegal-spinner="__ZEGAL__LOADING__SPINNER__"]': {
					'& > *': {
						margin: 0
					}
				},

				// spinner button label
				'& label': {
					transform: 'translateX(8px)'
				}
			}
		},

		align: {
			'& label, & .button-label': {
				justifyContent: 'flex-start'
			}
		},

		// state
		disabled: {
			backgroundColor: theme.color.default.disabled,
			boxShadow: 'none',
			pointerEvents: 'none'
		},

		active: {
			...primaryBackground(theme)
		},

		upperCase: {
			textTransform: 'uppercase',

			'& *': {
				textTransform: 'inherit'
			}
		},

		// color
		primary: {
			...primaryBackground(theme)
		},

		secondary: {
			...secondaryBackground(theme)
		},

		danger: {
			...dangerBackground(theme)
		},

		warning: {
			...warningBackground(theme),
			color: theme.color.dl_white + ' !important'

		},

		white: {
			'&, &:focus, &:hover, &:visited': {
				backgroundColor: theme.color.dl_white,
				color: theme.palette.text.primary
			}
		},

		black: {
			'&, &:focus, &:hover, &:visited': {
				backgroundColor: theme.color.dl_darkslate,
				color: theme.color.dl_white
			}
		},

		transparent: {
			'&, &:focus, &:hover': {
				background: 'transparent',
				boxShadow: 'none',
				color: 'inherit'
			}
		},

		// margin
		marginRatio: {
			'&none': {
				margin: 0
			}
		},

		// size
		xl: {
			padding: `${theme.typography.pxToRem(14)} ${theme.typography.pxToRem(24)}`,
			borderRadius: theme.typography.pxToRem(3)
		},

		lg: {
			padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(20)}`,
			borderRadius: theme.typography.pxToRem(3)
		},

		sm: {
			margin: `${theme.typography.pxToRem(1)}`,
			padding: `${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(10)}`,
			fontSize: theme.typography.pxToRem(11),
			borderRadius: theme.typography.pxToRem(3),
			minWidth: 'unset'
		},

		md: {
			padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(10)}`,
			fontSize: theme.typography.pxToRem(11),
			fontWeight: theme.typography.fontWeightMedium,
			borderRadius: 0,
			boxShadow: 'none !important',
			marginRight: theme.typography.pxToRem(12),

			'&:last-child': {
				marginRight: 0
			}
		},

		xs: {
			minWidth: theme.typography.pxToRem(64),
			margin: `${theme.typography.pxToRem(0)} ${theme.typography.pxToRem(1)}`,
			padding: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(8)}`,
			fontSize: theme.typography.pxToRem(10),
			borderRadius: theme.typography.pxToRem(2),
			fontWeight: theme.typography.fontWeightMedium,
		},

		auto: {
			height: 'auto',
			width: 'auto',
			padding: `${theme.typography.pxToRem(2)} ${theme.typography.pxToRem(4)}`,
			minWidth: 'unset',
			fontSize: 'inherit'
		},

		// link
		link: {
			'&, &:focus, &:hover, &:visited': {
				padding: 0,
				backgroundColor: 'transparent',
				boxShadow: 'none',
				display: 'inline-flex',
				color: 'inherit',
				minWidth: 'unset',
				fontSize: 'inherit'
			},

			'& .material-icons': {
				marginRight: theme.typography.pxToRem(6)
			},

			'&$disabled': {
				'&, &:focus, &:hover, &:visited': {
					color: theme.color.default.disabled + ' !important'
				}
			},

			'&$primary': {
				'&, &:focus, &:hover, &:visited': {
					color: theme.palette.primary.main
				}
			},

			'&$secondary': {
				'&, &:focus, &:hover, &:visited': {
					color: theme.palette.secondary.main
				}
			},

			'&$danger': {
				'&, &:focus, &:hover, &:visited': {
					color: theme.palette.error.main
				}
			},

			'&$black': {
				'&, &:focus, &:hover, &:visited': {
					color: theme.color.dl_darkslate
				}
			}
		},

		withIcon: {
			display: 'inline-flex',

			'& .material-icons': {
				margin: `0 ${theme.typography.pxToRem(6)} 0 0`
			},

			'&$xs': {
				'& .material-icons': {
					fontSize: theme.typography.pxToRem(16),
					margin: `0 ${theme.typography.pxToRem(3)} 0 0`
				},

				'& svg': {
					width: theme.typography.pxToRem(16),
					height: theme.typography.pxToRem(16)
				}
			},

			'&$sm': {
				'& .material-icons': {
					fontSize: theme.typography.pxToRem(20)
				}
			},

			'&$lg': {
				'& .material-icons': {
					fontSize: theme.typography.pxToRem(52)
				}
			},

			'&$auto': {
				height: 'auto',
				width: 'auto'
			}
		},

		justIcon: {
			height: theme.typography.pxToRem(36),
			width: theme.typography.pxToRem(36),
			margin: 0,
			borderRadius: '50%',
			padding: 0,
			minWidth: 'unset',

			'& .material-icons': {
				margin: 0,
				fontSize: theme.typography.pxToRem(22)
			},

			[theme.breakpoints.up('md')]: {
				height: theme.typography.pxToRem(40),
				width: theme.typography.pxToRem(40)
			},

			'&$xs': {
				height: theme.typography.pxToRem(20),
				width: theme.typography.pxToRem(20),

				'& .material-icons': {
					fontSize: theme.typography.pxToRem(16)
				}
			},

			'&$sm': {
				height: theme.typography.pxToRem(30),
				width: theme.typography.pxToRem(30),

				'& .material-icons': {
					fontSize: theme.typography.pxToRem(18)
				}
			},

			'&$lg': {
				height: theme.typography.pxToRem(46),
				width: theme.typography.pxToRem(46),

				'& .material-icons': {
					fontSize: theme.typography.pxToRem(24)
				}
			},

			'&$xl': {
				height: theme.typography.pxToRem(54),
				width: theme.typography.pxToRem(54),

				'& .material-icons': {
					fontSize: theme.typography.pxToRem(44)
				}
			},

			'&$auto': {
				height: 'auto',
				width: 'auto'
			}
		},

		// opacity
		opacity: {
			'&$disabled': {
				'&, &:focus, &:hover, &:visited': {
					color: theme.color.dl_dirtgrey + ' !important'
				}
			},

			'&$primary': {
				'&, &:focus, &:hover, &:visited': {
					backgroundColor: theme.palette.primary.light,
					color: theme.palette.primary.main
				}
			},

			'&$secondary': {
				'&, &:focus, &:hover, &:visited': {
					backgroundColor: theme.palette.secondary.light,
					color: theme.palette.secondary.contrastText
				}
			},

			'&$danger': {
				'&, &:focus, &:hover, &:visited': {
					backgroundColor: theme.palette.error.light,
					color: theme.color.default.error
				}
			},

			'&$warning': {
				'&, &:focus, &:hover, &:visited': {
					backgroundColor: fade(theme.color.default.warning, .8),
					color: theme.color.default.warning + ' !important'
				}
			},

			'&$black': {
				'&, &:focus, &:hover, &:visited': {
					backgroundColor: fade(theme.color.dl_darkslate, .8),
					color: theme.color.dl_darkslate
				}
			}
		},

		// simple
		simple: {
			backgroundColor: 'transparent',
			color: theme.palette.text.secondary,
			boxShadow: 'none',

			'&:focus, &:hover, &:visited': {
				backgroundColor: 'transparent',
				color: theme.palette.text.secondary
			},

			'&$disabled': {
				'&, &:focus, &:hover, &:visited': {
					color: theme.color.default.disabled + ' !important'
				}
			},

			'&$primary': {
				'&, &:focus, &:hover, &:visited': {
					color: theme.palette.primary.main
				}
			},

			'&$secondary': {
				'&, &:focus, &:hover, &:visited': {
					color: theme.palette.secondary.main
				}
			},

			'&$danger': {
				'&, &:focus, &:hover, &:visited': {
					color: theme.palette.error.main
				}
			},

			'&$warning': {
				'&, &:focus, &:hover, &:visited': {
					color: theme.color.default.warning + ' !important'
				}
			},

			'&$white': {
				'&, &:focus, &:hover, &:visited': {
					color: theme.color.dl_white
				}
			},

			'&$black': {
				'&, &:focus, &:hover, &:visited': {
					color: theme.color.dl_darkslate
				}
			},

			'&$transparent': {
				'&, &:focus, &:hover, &:visited': {
					color: 'inherit'
				}
			},

			// if justIcon with simple variant
			'&$justIcon, &$withIcon': {
				'&:hover, &:focus, &:visited': {
					backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
				},

				'&$auto': {
					backgroundColor: 'transparent !important'
				},

				'&$primary': {
					'&:hover, &:focus, &:visited': {
						backgroundColor: theme.palette.primary.light + ' !important'
					}
				}
			}
		},

		// border
		border: {
			'&, &:focus, &:hover, &:visited': {
				backgroundColor: theme.color.dl_white,
				color: theme.palette.text.secondary,
				border: 0,
				boxShadow: `inset 0px 0px 0px 1px ${theme.palette.divider}`
			},

			'&$simple': {
				'&, &:focus, &:hover, &:visited': {
					backgroundColor: 'transparent'
				}
			},

			'&$disabled': {
				'&, &:focus, &:hover, &:visited': {
					boxShadow: `inset 0px 0px 0px 1px ${theme.color.default.disabled}`,
					color: theme.color.default.disabled + ' !important'
				}
			},

			'&$active': {
				backgroundColor: theme.palette.primary.main,
				color: theme.palette.primary.contrastText
			},

			'&$primary': {
				'&, &:focus, &:hover, &:visited': {
					boxShadow: `inset 0px 0px 0px 1px ${theme.palette.primary.main} !important`,
					color: theme.palette.primary.main
				}
			},

			'&$secondary': {
				'&, &:focus, &:hover, &:visited': {
					boxShadow: `inset 0px 0px 0px 1px ${theme.palette.secondary.main} !important`,
					color: theme.palette.secondary.main
				}
			},

			'&$danger': {
				'&, &:focus, &:hover, &:visited': {
					boxShadow: `inset 0px 0px 0px 1px ${theme.palette.error.main} !important`,
					color: theme.palette.error.main
				}
			},

			'&$warning': {
				'&, &:focus, &:hover, &:visited': {
					boxShadow: `inset 0px 0px 0px 1px ${theme?.color?.default?.warning} !important`,
					color: theme.color.default.warning + ' !important'
				}
			},

			'&$black': {
				'&, &:focus, &:hover, &:visited': {
					boxShadow: `inset 0px 0px 0px 1px ${theme.color.dl_darkslate} !important`,
					color: theme.color.dl_darkslate
				}
			},

			'&$white': {
				'&, &:focus, &:hover, &:visited': {
					background: 'transparent',
					boxShadow: `inset 0px 0px 0px 1px ${theme.color.dl_white} !important`,
					color: theme.color.dl_white
				}
			},

			'&$transparent': {
				'&, &:focus, &:hover, &:visited': {
					border: 'none !important'
				}
			},

			'&$justIcon': {
				borderRadius: theme.typography.pxToRem(4)
			}
		},

		// noShadow
		noShadow: {
			boxShadow: 'none'
		},

		// simple
		fab: {
			display: 'flex',
			alignItems: 'center',
			width: 'auto',
			height: theme.typography.pxToRem(40),
			padding: `0 ${theme.typography.pxToRem(16)}`,
			minWidth: theme.typography.pxToRem(40),
			borderRadius: theme.typography.pxToRem(20),
			fontSize: 'inherit',

			'&$sm': {
				height: theme.typography.pxToRem(28),
				minWidth: theme.typography.pxToRem(28),
				padding: `0 ${theme.typography.pxToRem(7)} 0 ${theme.typography.pxToRem(12)}`,
				borderRadius: theme.typography.pxToRem(17)
			},

			'&$lg': {
				height: 61.5,
				minWidth: 61.5,
				borderRadius: '50%',

				'& .material-icons': {
					fontWeight: theme.typography.fontWeightBold
				}
			}
		},

		fullWidth: {
			width: '100%',
			minWidth: 'unset'
		}
	};
};

export default buttonStyle;
