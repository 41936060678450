import {checkAvailableEndPoint} from '@zegal/components/src/entities/user/userCollectionActions';
import App from '@zegal/components/src/base/core';

export default (self) => {
	return {
		get endpoint() {
			return '/orgs';
		},

		get paymentsBase() {
			return '/payments/accounts/';
		},

		getConfigbyJuriURL(juri, namespace) {
			return `${self.baseAppParameterEndpoint}/registrationForm?jurisdiction=${juri}&namespace=${namespace}`;
		},

		getAddCardConfigbyJuriURL(juri) {
			return `${self.baseAppParameterEndpoint}/creditCardForm?jurisdiction=${juri}`;
		},

		checkAvailableEndPoint,

		sendConfirmationEndpoint(userId) {
			return `${self.usersBase}/${userId}/confirmation`;
		},

		profileUpdateEndpoint(_id) {
			return `${self.endpoint}/${_id}/profile`;
		},

		addPaymentMethodURI(orgID) {
			return `${self.paymentsBase}${orgID}/paymentmethods?default=true`;
		},

		getOrderEndpoint(orgID) {
			return `${self.paymentsBase}${orgID}/order`;
		},

		getPayEndpoint(orgID) {
			return `${self.paymentsBase}${orgID}/order/pay`;
		},

		getGoogleAuthEndpoint() {
			const APIRoot = App.getConfig('APIRoot');
			const AppRoot = App.getConfig('AppRoot');
			const url = `${APIRoot}/authorization/auth/google/login?redirect_uri=${AppRoot}/onboarding/&authCookie=true`;
			return url;
		},

		getMicrosoftAuthEndpoint() {
			const APIRoot = App.getConfig('APIRoot');
			const AppRoot = App.getConfig('AppRoot');
			const url = `${APIRoot}/authorization/auth/microsoft/login?redirect_uri=${AppRoot}/onboarding/&authCookie=true`;
			return url;
		}
	};
};
