import {emailValidationWithRegEx} from '@zegal/components/src/base/common/utils/simpleValidatorCustomRules';

export default (self) => {
	return {
		get primary_name() {
			return {
				given_name: self.firstName,
				surname: self.lastName
			};
		},

		isValidEmail(email = self.email) {
			return emailValidationWithRegEx(email);
		},

		registrationEndpoint() {
			if (self.invitationExists()) {
				return `/member-invitations/${self.invitationId}/accept?token=${encodeURIComponent(
					self.token
				)}&register=true`;
			}
			return `${self.authBase}/register?dash=true`;
		},

		registerEndpoint() {
			return '/v2/auth/register';
		},

		invitationExists() {
			if (self.token && self.invitationId) {
				return true;
			}
			return false;
		}
	};
};
