import React from 'react';
import {map} from 'lodash';

import classNames from 'classnames';
import {withStyles, createStyles} from '@material-ui/core/styles';
import Dialog, {DialogProps} from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Wrapper from '../wrappers/wrapper';
import Button from '../buttons/button';
import IconButton from '../buttons/icon';
import modalStyles from '../assets/styles/modals/modal';

export interface IModal {
	closeModal: () => void;
}
export interface IButton {
	text: string;
	type: string;
	handleClick?: () => void;
}

interface DialogComponentProps extends DialogProps {
	title?: any;
	customMode?: boolean; // if custom mode, then don't show the dialog wrapping
	subtitle?: any;
	buttons?: IButton[];
	children: any;
	classes?: any;
	showCloseButton?: any; // for to show close button
	onExit?: any; // using for unit-test
	collapsed?: boolean;
	fullScreen?: any;
	stickOnBottomXs?: boolean;
	staticHeight?: boolean;
	hideBackdrop?: boolean;
	disableBackdropClick?: boolean;
	showTopBorder?: boolean;
	disableEscapeKeyDown?: boolean;
	disableEnforceFocus?: boolean;
	customMidWidth?: boolean;

	// size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
	// color: PropTypes.oneOf(['secondary', false])
	size?: any;
	fullWidth?: any;
	color?: string;
	style?: React.CSSProperties;
	Header?: React.ReactElement;

	open?: boolean;
	handleClick?: (button: any) => void;
	handleOnClose?: (event: any, reason?: string, cb?: () => void) => void;
	customHandleOnClose?: (event: any, reason?: string, cb?: () => void) => void;
	onEscapeKeyDown?: (handleClose?: () => void) => void;
	onEnter?: (handleClose?: () => void) => void;
}

const DialogComponent: React.SFC<DialogComponentProps> = ({
	title,
	customMode,
	subtitle,
	buttons,
	children,
	classes,
	showCloseButton, // for to show close button
	onExit, // using for unit-test
	collapsed,
	size,
	fullScreen,
	stickOnBottomXs,
	hideBackdrop,
	staticHeight,
	disableBackdropClick,
	showTopBorder,
	transitionDuration,
	style,
	color,
	disableEscapeKeyDown,
	onEscapeKeyDown,
	fullWidth = false,
	Header,
	disableEnforceFocus = true,
	customMidWidth,

	open,
	handleClick,
	handleOnClose,
	customHandleOnClose,

	onEnter
}) => {
	const [isOpen, setOpen] = React.useState(false);

	//@ts-ignore
	window.closeModal = () => {
		setOpen(false);
	};

	React.useLayoutEffect(() => {
		setOpen(open === undefined ? true : open);
	}, [open]);

	React.useEffect(() => {
		setTimeout(() => {
			if (fullScreen && isOpen) {
				document.body.style.cssText = 'overflow: hidden !important';
			}
		}, 0);
	}, [isOpen, fullScreen]);

	const handleRequestClose = (event?: any, reason?: string) => {
		// for external control of closing modal
		if (customHandleOnClose) {
			return customHandleOnClose(event, reason, () => setOpen(false));
		}

		if (handleOnClose) {
			handleOnClose(event, reason, () => setOpen(false));
		}

		setOpen(false);
	};

	const handleOnEscape = () => {
		if (onEscapeKeyDown) {
			return onEscapeKeyDown(handleRequestClose);
		}

		return handleRequestClose;
	};

	const handleOnEnter = () => {
		if (onEnter) {
			return onEnter(handleRequestClose);
		}

		return undefined;
	};

	const handleButton = (button: any) => () => {
		handleRequestClose();

		// setOpen(true)

		if (handleClick) {
			handleClick(button);
		} else {
			button.handleClick && button.handleClick();
		}
	};

	const WrappedChildren = React.Children.map(children, (child) => {
		// it's a React component, so add props
		if (typeof child.type === 'function') {
			return React.cloneElement(child, {
				isOpen,
				handleClose: handleRequestClose,
				title: DialogTitle
			});
		} else {
			// regular element, React yells when props are are passed onto it
			return React.cloneElement(child);
		}
	});

	// class distribution
	let classProps = {
		[classes.dialog]: true,
		[classes.dialogPaper]: true,
		[classes.showTopBorder]: showTopBorder,
		[classes.staticHeight]: staticHeight,
		[classes.fullScreen]: fullScreen,
		[classes.stickOnBottomXs]: stickOnBottomXs,
		// @ts-ignore
		[classes[color]]: color
	};

	let dialogClassName = classNames(classProps);

	let dialogContentClassName = classNames({
		[classes.dialogContent]: true,
		collapsed: collapsed
	});

	const customWidth = classNames({
		[classes.customMediumWidth]: size === false && customMidWidth
	});

	return (
		<Dialog
			open={isOpen}
			onClose={handleRequestClose}
			onEscapeKeyDown={handleOnEscape}
			classes={{
				paper: dialogClassName,
				paperWidthMd: classes.paperMd,
				paperWidthFalse: customWidth
			}}
			BackdropProps={{
				className: !hideBackdrop ? classes.backDrop : '',
				invisible: hideBackdrop
			}}
			transitionDuration={transitionDuration}
			PaperProps={{
				style
			}}
			disableBackdropClick={disableBackdropClick}
			disableEscapeKeyDown={disableEscapeKeyDown}
			// using for unit-test
			onExit={onExit}
			maxWidth={size}
			fullWidth={fullWidth}
			onEnter={handleOnEnter}
			disableEnforceFocus={disableEnforceFocus}
		>
			{showCloseButton && !Header && (
				<IconButton simple icon='clear' className={classes.dialogCloseButton} onClick={handleRequestClose} />
			)}

			{Header && (
				<Header handleRequestClose={handleRequestClose}>
					{' '}
					<IconButton
						simple
						icon='clear'
						className={classes.dialogCloseButton}
						onClick={handleRequestClose}
					/>{' '}
				</Header>
			)}

			{title && (
				<DialogTitle disableTypography classes={{root: classes.dialogTitle}}>
					<Wrapper style={{marginBottom: subtitle ? 8 : 0}}>{title}</Wrapper>

					{subtitle && (
						<DialogContentText color='initial' variant='body1'>
							{subtitle}
						</DialogContentText>
					)}
				</DialogTitle>
			)}

			{customMode ? (
				WrappedChildren
			) : (
				<DialogContent className={dialogContentClassName}>
					{WrappedChildren}

					{buttons && (
						<Wrapper alignItems='center'>
							{map(buttons, (button) => (
								<Button
									key={button.text}
									onClick={handleButton(button)}
									color={button.type === 'text' ? 'primary' : 'secondary'}
									simple={button.type === 'text' ? true : false}
									disableRipple
								>
									{button.text}
								</Button>
							))}
						</Wrapper>
					)}
				</DialogContent>
			)}
		</Dialog>
	);
};

export default withStyles(createStyles(modalStyles))(DialogComponent);
