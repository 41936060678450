import BaseError from './error';
import errors from '../constants';

const {GENERAL} = errors;

export default class GeneralError {
	constructor(message, meta) {
		// super()
		throw new BaseError(GENERAL, message, meta);
	}
}
