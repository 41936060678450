import {types} from 'mobx-state-tree';

import generalActions from '@zegal/components/src/base/common/stores/_general';
import userUrls from '@zegal/components/src/entities/user/userUrls';
import appParameterUrls from '@zegal/components/src/entities/misc/urls/appParameterUrls';
import urls from './urls';
import actions from './actions';
import usersStoreActions from '@zegal/components/src/entities/user/userCollectionActions';
import views from './views';

export default types
	.model('Org', {
		_id: '',
		authentication_type: '',
		business_year: '',
		company: '',
		// consent: types.optional(types.boolean, false),
		consent: '',
		dial_code: '',
		contact_number: '',
		email: '',
		express: types.optional(types.boolean, true),
		industry: '',
		market: '',
		no_of_employees: '',
		password: '',
		firstName: '',
		lastName: '',
		role: '',
		register_ref: 'standard',
		country_code: 'HK',
		currentUrl: '',
		namespace: '',
		templateName: ''
	})

	.actions(generalActions)
	.actions(actions)
	.actions(usersStoreActions)

	.volatile(() => ({
		token: null,
		redirectedBy: null,
		invitationId: null,
		emailAlreadyTakenError: null, // variable to set error message if email already taken
		advisory_code: null,
		userData: null
	}))

	.views(userUrls)
	.views(urls)
	.views(appParameterUrls)
	.views(views);
